import React, { Component } from "react";
import {
  CustomInput,
  CustomTextArea
} from "../../../components/Form/Inputs/Inputs";

import { CustomButton } from "../../../components/Buttons/Button";
import { addPlayerValidation } from "../../../functions/validateFunctions";
import {
  getAllCategories,
  getPlayer,
  updatePlayer
} from "../../../functions/postFunctions";
import DatePicker from "react-date-picker";
import SearchableDropdown from "../../../components/Form/Dropdown/SearchableDropdown";
import { formatDateForSafari } from "../../../functions/functions";

class EditPlayer extends Component {
  state = {
    id: 0,
    name: "",
    number: null,
    position: "",
    birth_date: new Date(),
    birth_place: "",
    height: null,
    weight: null,
    nationality: "",
    clubs: "",
    titles: "",
    statement: "",
    errors: [],
    showErrorPopup: false,
    showWarningPopup: false,
    image: null,
    currentImage: "",
    active: "",
    showUploadPopup: false,
    uploadComplete: false,
    currentPosition: { value: "", label: "" },
    positionList: [
      {
        value: "golman",
        label: "Golman"
      },
      {
        value: "vezni red",
        label: "Vezni Red"
      },
      {
        value: "napad",
        label: "Napad"
      }
    ],
    currentCategory: { name: "", value: "" },
    categories: []
  };

  componentDidMount() {
    this.props.setBreadcrumbs("Izmjeni igrača");
    getPlayer(this.props.match.params.id).then(res => {
      if (res.success) {
        const { player } = res;
        const currentPosition = this.state.positionList.find(
          e => (e.value = player.position)
        );
        this.setState({
          id: player.id,
          name: player.name,
          birth_date: new Date(formatDateForSafari(player.birth_date)),
          birth_place: player.birth_place,
          nationality: player.nationality,
          number: player.number,
          height: player.height,
          weight: player.weight,
          clubs: player.clubs,
          titles: player.titles,
          statement: player.statement,
          currentImage: player.image,
          currentPosition,
          position: player.position
        });
      }
    });
    getAllCategories().then(res => {
      if (res.success) {
        const categories = res.categories.map(e => ({
          value: e.id,
          label: e.name
        }));
        const { category } = this.props.match.params;
        const currentCategory = categories.find(
          e => e.value === parseInt(category)
        );
        this.setState({
          categories,
          category,
          currentCategory
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.errors != this.state.errors && this.state.errors.length > 0) {
      this.setState({ showErrorPopup: true });
    }
    if (prevState.stepOne !== this.state.stepOne) {
      const item = document.querySelector(".gallery");
      this.navigationMove(item, "gallery");
    }
  }

  handleInput = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  handleFileInput = event => {
    this.setState({ image: [...event] });
  };

  removePopup = () => {
    this.setState({ showErrorPopup: false, showWarningPopup: false });
  };

  handleSubmit = event => {
    event.preventDefault();

    const {
      name,
      number,
      position,
      birth_date,
      birth_place,
      height,
      weight,
      nationality,
      clubs,
      statement,
      titles,
      image,
      id,
      currentCategory
    } = this.state;
    const dataObject = {
      name,
      number: number || 0,
      position: position !== "" ? position : "/",
      birth_date: birth_date.toISOString(),
      birth_place: birth_place !== "" ? birth_place : "/",
      height: height || 0,
      weight: weight || 0,
      nationality: nationality !== "" ? nationality : "/",
      clubs: clubs !== "" ? clubs : "/",
      statement,
      titles,
      id,
      category: currentCategory.value
    };

    const errors = addPlayerValidation(
      this.state,
      true,
      currentCategory.value === 1
    );

    if (errors.length === 0) {
      const data = new FormData();

      Object.keys(dataObject).forEach(key => {
        data.append(key, dataObject[key]);
      });
      if (image) {
        image.forEach(e => {
          data.append("image", e);
        });
        this.setState({ showUploadPopup: true });
      }
      const config = {
        onUploadProgress: progressEvent => {
          const completed = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          this.setState({
            completed,
            uploadComplete: completed === 100
          });
        }
      };

      updatePlayer(data, this.props.token, config).then(res => {
        if (res.success) {
          this.props.history.push("/igraci");
        } else {
          alert(res.message);
        }
      });
    } else {
      this.setState({ errors });
    }
  };

  render() {
    const { positionList, categories, currentCategory } = this.state;
    let server;
    if (process.env.NODE_ENV === "development") {
      server = "http://127.0.0.8/";
    } else {
      server = "https://admin.fk-buducnost.me/";
    }
    return (
      <div className="wrapper">
        <div
          className={`errorPopup ${
            this.state.showWarningPopup ? "popupShown" : ""
          }`}
        >
          <div className="content py-20 px-10 d-flex justify-content-between flex-column">
            <h3 className="f-s-22 f-w-4 uppercase text-center text-color-red">
              Upozorenje
            </h3>
            <h5 className="f-s-16 f-w-4 uppercase text-center">
              Nijedna fotografija nije selektovana. Ukoliko nastavite default-na
              cover fotografija će biti korištena
            </h5>
            <div className="button text-center mt-30">
              {/*<CustomButton className="mr-10 red" onClick={this.createPage}>*/}
              {/*  Nastavi*/}
              {/*</CustomButton>*/}
              <CustomButton onClick={this.removePopup}>Zatvori</CustomButton>
            </div>
          </div>
        </div>
        <div
          className={`errorPopup ${
            this.state.showUploadPopup ? "popupShown" : ""
          }`}
        >
          <div className="content py-20 px-10 d-flex justify-content-center flex-column">
            <h3 className="f-s-22 f-w-4 uppercase text-center text-color-primary">
              {!this.state.uploadComplete
                ? "Upload u toku"
                : "Optimizacija u toku"}
            </h3>
            {!this.state.uploadComplete ? (
              <div className="uploadBar d-flex align-items-center p-r">
                <div className="barWrapper">
                  <span
                    className="bar d-flex align-items-center justify-content-center"
                    style={{ width: `${this.state.completed}%` }}
                  />
                </div>
                <span className="outerProgress f-s-16 center-a-b text-color-primary">
                  {this.state.completed}%
                </span>
              </div>
            ) : (
              <div className="optimization loader d-flex justify-content-center align-items-center">
                <div className="fancy-spinner">
                  <div className="ring" />
                  <div className="ring" />
                  <div className="dot" />
                </div>
              </div>
            )}
          </div>
        </div>
        {this.state.stepOne && (
          <div className="innerNavigation pb-10 mb-40">
            <span
              className="f-s-16 mr-20 uppercase text-color-primary link"
              onClick={e => this.navigationMove(e.currentTarget, "content")}
            >
              Sadržaj
            </span>
            <span
              className="f-s-16 uppercase text-color-font-l link gallery"
              onClick={e => this.navigationMove(e.currentTarget, "gallery")}
            >
              Galerija
            </span>
            <span className="indicator" ref={this.underlineRef} />
          </div>
        )}
        <div className="contentWrapper">
          <div
            className={` errorPopup ${
              this.state.showErrorPopup ? "popupShown" : ""
            }`}
          >
            <div className="content py-20 px-10 d-flex justify-content-between flex-column">
              <h3 className="f-s-18 f-w-4 uppercase text-center">
                Problemi prilikom kreiranja stranice
              </h3>
              <ul className="mt-30">
                {this.state.errors.map((e, index) => {
                  return (
                    <li key={index} className="f-s-16 text-center">
                      {e}
                    </li>
                  );
                })}
              </ul>
              <div className="button text-center mt-30">
                <CustomButton onClick={this.removePopup}>Zatvori</CustomButton>
              </div>
            </div>
          </div>
          <form onSubmit={null}>
            <h3 className="f-s-20 text-color-primary uppercase">
              <span className="f-w-4">
                Izmjena igrača, selektovana kategorija:{" "}
              </span>
              {this.state.currentCategory.label}
            </h3>
            <div className="row mt-20">
              <div className="col-md-4">
                <CustomInput
                  label="Ime i prezime"
                  value={this.state.name}
                  handleChange={this.handleInput}
                  name="name"
                />
              </div>
              <div className="col-md-3">
                <DatePicker
                  onChange={e => this.handleInput("birth_date", e, 0)}
                  value={this.state.birth_date}
                  locale={"bs-BS"}
                />
              </div>
              <div className="col-md-5">
                <CustomInput
                  label="Mjesto rođenja"
                  value={this.state.birth_place}
                  handleChange={this.handleInput}
                  name="birth_place"
                />
              </div>
            </div>
            <div className="row mt-20">
              <div className="col-md-3">
                <CustomInput
                  label="Nacionalnost"
                  value={this.state.nationality}
                  handleChange={this.handleInput}
                  name="nationality"
                />
              </div>
              <div className="col-md-2">
                <CustomInput
                  label="Broj"
                  value={this.state.number}
                  handleChange={this.handleInput}
                  name="number"
                  type={"number"}
                />
              </div>
              <div className="col-md-2">
                <CustomInput
                  label="Visina"
                  value={this.state.height}
                  handleChange={this.handleInput}
                  name="height"
                  type={"number"}
                />
              </div>
              <div className="col-md-2">
                <CustomInput
                  label="Težina"
                  value={this.state.weight}
                  handleChange={this.handleInput}
                  name="weight"
                  type={"number"}
                />
              </div>
              <div className="col-md-3">
                <SearchableDropdown
                  data={positionList}
                  placeholder="Pozicija"
                  name="position"
                  handleChange={this.handleInput}
                  value={this.state.currentPosition}
                />
              </div>
            </div>
            <div className="row mt-20">
              <div className="col-md-5">
                <CustomInput
                  label="Klubovi"
                  value={this.state.clubs}
                  handleChange={this.handleInput}
                  name="clubs"
                />
              </div>
              <div className="col-md-5">
                <CustomInput
                  label="Trofeji"
                  value={this.state.titles}
                  handleChange={this.handleInput}
                  name="titles"
                />
              </div>
              <div className="col-md-2">
                <SearchableDropdown
                  data={categories}
                  placeholder="Tim"
                  name="currentCategory"
                  handleChange={this.handleInput}
                  value={currentCategory}
                />
              </div>
            </div>
            <div className="row mt-20">
              <div className="col-12">
                <CustomTextArea
                  label="Izjava"
                  value={this.state.statement}
                  handleChange={this.handleInput}
                  name="statement"
                  className="textArea"
                />
              </div>
            </div>
            <div className="row mt-40">
              <div className="col-12">
                <div className="imgContainer mb-40">
                  <img
                    src={`${server}${this.state.currentImage}`}
                    alt=""
                    width={200}
                  />
                </div>
                <div className="galleryWrapper">
                  <CustomInput
                    label="Izmjeni fotografiju"
                    value={null}
                    handleChange={this.handleFileInput}
                    name="image"
                    type="file"
                  />
                  {this.state.image ? (
                    <div className="selectedImages my-20 py-10 px-15">
                      <h3 className="f-s-16 text-color-primary f-w-4">
                        Fotografija odabrana za upload:
                      </h3>
                      <ul className="mt-20">
                        {this.state.image.map((e, index) => {
                          return (
                            <li key={index} className="f-s-13">
                              {e.name}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="row mt-30">
          <div className="col-12 d-flex justify-content-end">
            <CustomButton onClick={this.handleSubmit}>
              Izmjeni igrača
            </CustomButton>
          </div>
        </div>
      </div>
    );
  }
}

export default EditPlayer;
