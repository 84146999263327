import React, { Component } from "react";
import {
  deletePage,
  deleteTeam,
  getAllCompetitions,
  getAllTeams
} from "../../functions/postFunctions";
import { CustomButton, LinkButton } from "../../components/Buttons/Button";
import Table from "../../components/Table/Table";
import AddTeam from "../../components/AddTeam/AddTeam";
import EditTeam from "../../components/EditTeam/EditTeam";
import { TeamPlaceholder } from "../../components/icons/icons";

class TeamsPage extends Component {
  state = {
    theads: ["Logo", "Ime", "Lokacija", "Akcije"],
    teams: [],
    showAddTeam: false,
    showEditTeam: false,
    teamForEdit: null
  };

  componentDidMount() {
    this.props.setBreadcrumbs("Timovi");
    getAllTeams().then(res => this.setState({ teams: res.teams }));
  }

  handleDeletePage = id => {
    deleteTeam(id, this.props.token).then(res => {
      if (res.success) {
        this.setState({ teams: res.teams });
      }
    });
  };

  showModal = (name, id) => {
    let team;
    if (id) {
      const { teams } = this.state;
      team = teams.find(e => e.id === id);
    }
    this.setState({ [name]: true, teamForEdit: team });
  };

  hideModal = () => {
    this.setState({
      showAddTeam: false,
      showEditTeam: false,
      teamForEdit: null
    });
  };

  addTeamToList = team => {
    this.setState(prevState => ({
      teams: [...prevState.teams, team],
      showAddTeam: false
    }));
  };

  refreshList = () => {
    getAllTeams().then(res =>
      this.setState({
        teams: res.teams,
        showEditTeam: false,
        teamForEdit: null
      })
    );
  };

  render() {
    const { theads, teams, teamForEdit } = this.state;
    let server;
    if (process.env.NODE_ENV === "development") {
      server = "http://127.0.0.8/";
    } else {
      server = "https://admin.fk-buducnost.me/";
    }
    return (
      <div>
        <div className="pageTop mb-40">
          <CustomButton onClick={() => this.showModal("showAddTeam")}>
            Dodaj tim
          </CustomButton>
        </div>
        <AddTeam
          visible={this.state.showAddTeam}
          hideModal={this.hideModal}
          token={this.props.token}
          addTeamToList={this.addTeamToList}
        />
        <EditTeam
          visible={this.state.showEditTeam}
          hideModal={this.hideModal}
          token={this.props.token}
          refreshList={this.refreshList}
          team={teamForEdit}
        />
        <Table theads={theads}>
          {teams.map((e, index) => {
            return (
              <tr key={index}>
                <td className="td px-10">
                  {e.logo !== "" ? (
                    <img
                      src={`${server}/${e.logo}`}
                      alt={e.slug}
                      style={{ maxHeight: "50px" }}
                    />
                  ) : (
                    <TeamPlaceholder className="fill" />
                  )}
                </td>
                <td className="td px-10">
                  <span className="f-s-16">{e.name}</span>
                </td>
                <td className="td px-10">
                  <span className="f-s-16">{e.location}</span>
                </td>
                <td className="td px-10" style={{ minWidth: "220px" }}>
                  <CustomButton
                    onClick={() => this.showModal("showEditTeam", e.id)}
                    className="mr-20"
                  >
                    Izmjeni
                  </CustomButton>
                  <CustomButton
                    onClick={() => this.handleDeletePage(e.id)}
                    className="red"
                  >
                    Izbriši
                  </CustomButton>
                </td>
              </tr>
            );
          })}
        </Table>
      </div>
    );
  }
}

export default TeamsPage;
