import React, { Component } from "react";
import { getAllPages } from "../../functions/postFunctions";
import { LinkButton } from "../../components/Buttons/Button";

class HomePage extends Component {
  render() {
    return (
      <div>
        <LinkButton href="dodaj-stranicu">Dodaj stranicu</LinkButton>
      </div>
    );
  }
}

export default HomePage;
