import React, { Component } from "react";
import { CustomInput } from "../../../components/Form/Inputs/Inputs";

import { CustomButton } from "../../../components/Buttons/Button";
import { addGameValidation } from "../../../functions/validateFunctions";
import {
  addGame,
  addPage,
  getAllCompetitions,
  getAllTeams
} from "../../../functions/postFunctions";
import DateTimePicker from "react-datetime-picker";

import SearchableDropdown from "../../../components/Form/Dropdown/SearchableDropdown";

class AddGame extends Component {
  state = {
    host: 0,
    guest: 0,
    host_goals: null,
    guest_goals: null,
    date: new Date(),
    competition: 0,
    competitionList: [],
    location: "",
    teamsList: [],
    errors: [],
    showErrorPopup: false,
    showWarningPopup: false,
    images: null,
    active: "",
    listOfImages: [],
    stepOne: false,
    edit: false,
    completed: 0,
    showUploadPopup: false,
    uploadComplete: false
  };

  componentDidMount() {
    this.props.setBreadcrumbs("Dodaj utakmicu");
    getAllTeams().then(res => {
      const teamsList = res.teams.map(e => {
        return { value: e.id, label: e.name };
      });
      this.setState({ teamsList });
    });
    getAllCompetitions().then(res => {
      const competitionList = res.competitions.map(e => {
        return { value: e.id, label: e.name };
      });
      this.setState({ competitionList });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.errors != this.state.errors && this.state.errors.length > 0) {
      this.setState({ showErrorPopup: true });
    }
    if (prevState.stepOne !== this.state.stepOne) {
      const item = document.querySelector(".gallery");
      this.navigationMove(item, "gallery");
    }
  }

  handleInput = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  handleFileInput = event => {
    this.setState({ images: [...event] });
  };

  removePopup = () => {
    this.setState({ showErrorPopup: false, showWarningPopup: false });
  };

  handleSubmit = event => {
    event.preventDefault();
    const errors = addGameValidation(this.state);
    const {
      host,
      host_goals,
      guest,
      guest_goals,
      date,
      location,
      competition
    } = this.state;
    const data = {
      host_id: host,
      guest_id: guest,
      host_goals,
      guest_goals,
      date,
      competition_id: competition,
      location
    };
    if (errors.length === 0) {
      addGame(data, this.props.token).then(res => {
        if (res.success) {
          this.props.history.push("/utakmice");
        } else {
          alert(res.message);
        }
      });
    } else {
      this.setState({ errors });
    }
  };

  render() {
    const { teamsList, competitionList } = this.state;
    return (
      <div className="wrapper">
        <div
          className={`errorPopup ${
            this.state.showWarningPopup ? "popupShown" : ""
          }`}
        >
          <div className="content py-20 px-10 d-flex justify-content-between flex-column">
            <h3 className="f-s-22 f-w-4 uppercase text-center text-color-red">
              Upozorenje
            </h3>
            <h5 className="f-s-16 f-w-4 uppercase text-center">
              Nijedna fotografija nije selektovana. Ukoliko nastavite default-na
              cover fotografija će biti korištena
            </h5>
            <div className="button text-center mt-30">
              {/*<CustomButton className="mr-10 red" onClick={this.createPage}>*/}
              {/*  Nastavi*/}
              {/*</CustomButton>*/}
              <CustomButton onClick={this.removePopup}>Zatvori</CustomButton>
            </div>
          </div>
        </div>
        <div
          className={`errorPopup ${
            this.state.showUploadPopup ? "popupShown" : ""
          }`}
        >
          <div className="content py-20 px-10 d-flex justify-content-center flex-column">
            <h3 className="f-s-22 f-w-4 uppercase text-center text-color-primary">
              {!this.state.uploadComplete
                ? "Upload u toku"
                : "Optimizacija u toku"}
            </h3>
            {!this.state.uploadComplete ? (
              <div className="uploadBar d-flex align-items-center p-r">
                <div className="barWrapper">
                  <span
                    className="bar d-flex align-items-center justify-content-center"
                    style={{ width: `${this.state.completed}%` }}
                  />
                </div>
                <span className="outerProgress f-s-16 center-a-b text-color-primary">
                  {this.state.completed}%
                </span>
              </div>
            ) : (
              <div className="optimization loader d-flex justify-content-center align-items-center">
                <div className="fancy-spinner">
                  <div className="ring" />
                  <div className="ring" />
                  <div className="dot" />
                </div>
              </div>
            )}
          </div>
        </div>
        {this.state.stepOne && (
          <div className="innerNavigation pb-10 mb-40">
            <span
              className="f-s-16 mr-20 uppercase text-color-primary link"
              onClick={e => this.navigationMove(e.currentTarget, "content")}
            >
              Sadržaj
            </span>
            <span
              className="f-s-16 uppercase text-color-font-l link gallery"
              onClick={e => this.navigationMove(e.currentTarget, "gallery")}
            >
              Galerija
            </span>
            <span className="indicator" ref={this.underlineRef} />
          </div>
        )}
        <div className="contentWrapper">
          <div
            className={` errorPopup ${
              this.state.showErrorPopup ? "popupShown" : ""
            }`}
          >
            <div className="content py-20 px-10 d-flex justify-content-between flex-column">
              <h3 className="f-s-18 f-w-4 uppercase text-center">
                Problemi prilikom kreiranja stranice
              </h3>
              <ul className="mt-30">
                {this.state.errors.map((e, index) => {
                  return (
                    <li key={index} className="f-s-16 text-center">
                      {e}
                    </li>
                  );
                })}
              </ul>
              <div className="button text-center mt-30">
                <CustomButton onClick={this.removePopup}>Zatvori</CustomButton>
              </div>
            </div>
          </div>
          <form onSubmit={null}>
            {/*<h2 className="uppercase f-s-20 text-color-primary f-w-4">*/}
            {/*  Crnogorski*/}
            {/*</h2>*/}
            <div className="row mt-20">
              <div className="col-md-4">
                <SearchableDropdown
                  data={teamsList}
                  placeholder="Domaćin"
                  name="host"
                  handleChange={this.handleInput}
                />
              </div>
              <div className="col-md-2">
                <CustomInput
                  label="Golovi domaćin"
                  value={this.state.host_goals}
                  handleChange={this.handleInput}
                  name="host_goals"
                  type={"number"}
                />
              </div>
              <div className="col-md-2">
                <CustomInput
                  label="Golovi Gost"
                  value={this.state.guest_goals}
                  handleChange={this.handleInput}
                  name="guest_goals"
                  type={"number"}
                />
              </div>
              <div className="col-md-4">
                <SearchableDropdown
                  data={teamsList}
                  placeholder="Gost"
                  name="guest"
                  handleChange={this.handleInput}
                />
              </div>
            </div>
            <div className="row mt-20">
              <div className="col-md-4">
                <DateTimePicker
                  onChange={e => this.handleInput("date", e, 0)}
                  value={this.state.date}
                  locale={"bs-BS"}
                />
              </div>
              <div className="col-md-4">
                <SearchableDropdown
                  data={competitionList}
                  placeholder="Takmičenje"
                  name="competition"
                  handleChange={this.handleInput}
                />
              </div>
              <div className="col-md-4">
                <CustomInput
                  label="Lokacija"
                  value={this.state.location}
                  handleChange={this.handleInput}
                  name="location"
                />
              </div>
            </div>
          </form>
        </div>
        <div className="row mt-20">
          <div className="col-12">
            <span className="f-s-14 text-color-primary ">
              <i>
                * Napomena: Polja golovi ostaviti prazna ukoliko utakmica nije
                kompletirana
              </i>
            </span>
          </div>
        </div>
        <div className="row mt-30">
          <div className="col-12 d-flex justify-content-end">
            <CustomButton onClick={this.handleSubmit}>
              Dodaj utakmicu
            </CustomButton>
          </div>
        </div>
      </div>
    );
  }
}

export default AddGame;
