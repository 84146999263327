/* eslint-disable no-useless-escape */
/* eslint-disable no-use-before-define */
/* eslint-disable eqeqeq */
/* eslint-disable no-redeclare */

import swaps from "../utils/swaps";

const formatMoney = (n, c, d, t) => {
  var c = isNaN((c = Math.abs(c))) ? 2 : c,
    d = d == undefined ? "." : d,
    t = t == undefined ? "," : t,
    s = n < 0 ? "-" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (j = i.length) > 3 ? j % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
};

const moveIndicator = target => {
  const element = document.querySelector(".indicator");
  const elementPosition = element.offsetTop;
  const parent = document.querySelector(".navContainer");
  const targetElement = target - 8.5;

  if (!(element.offsetTop === targetElement + 8.5 + 9)) {
    element.style.transition = "all 0.2s ease-out";
    if (elementPosition < targetElement) {
      element.style.top = "";
      element.style.bottom = parent.offsetHeight - elementPosition - 33 + "px";
      setTimeout(() => {
        element.style.bottom = parent.offsetHeight - targetElement - 50 + "px";
      }, 4);
      element.style.height = "66px";
      element.style.width = "2px";
      setTimeout(() => {
        element.style.top = "";
        element.style.width = "4px";
        element.style.height = "33px";
      }, 200);
    } else if (elementPosition > targetElement) {
      element.style.top = elementPosition + "px";
      element.style.bottom = "";
      setTimeout(() => {
        element.style.top = targetElement + 17 + "px";
      }, 4);
      element.style.height = "66px";
      element.style.width = "2px";

      setTimeout(() => {
        element.style.bottom = "";
        element.style.width = "4px";
        element.style.height = "33px";
      }, 200);
    }
  }

  // if (this.state.animation === 1) {
  //   element.style.transition =
  //     "transition: height 0.2s ease-out, transform 0.2s ease-out;";
  //   const targetElement = e.target.offsetTop + 8.5;
  //   element.style.transform = "translateY(" + targetElement + "px)";
  // } else if (this.state.animation === 2) {
  //   element.style.transition =
  //     "transition: height 0.2s ease-out, transform 0.2s ease-out;";

  //   const targetElement = e.target.offsetTop - 8.5;
  //   if (elementPosition < e.target.offsetTop) {
  //     element.style.top = elementPosition + "px";
  //     element.style.bottom = "";
  //     const height = targetElement - elementPosition + 50;
  //     element.style.height = height + "px";

  //     setTimeout(() => {
  //       element.style.top = "";
  //       element.style.bottom =
  //         parent.offsetHeight - targetElement - 50 + "px";
  //       element.style.height = "33px";
  //     }, 200);
  //   } else if (elementPosition > e.target.offsetTop) {
  //     element.style.bottom =
  //       parent.offsetHeight - elementPosition - 33 + "px";
  //     element.style.top = "";
  //     const height = elementPosition + 17 - targetElement;
  //     element.style.height = height + "px";

  //     setTimeout(() => {
  //       element.style.bottom = "";
  //       element.style.top = targetElement + 17 + "px";
  //       element.style.height = "33px";
  //     }, 200);
  //   }
  // }
};

const evaluateHeight = () => {
  window.onresize = null;
  let elements = document.querySelectorAll(".scrollableContent");
  elements.forEach(e => {
    let parent = e.parentNode.parentNode;
    if (e.offsetHeight > e.parentNode.parentNode.offsetHeight) {
      parent.classList.add("scrollbar");
    } else {
      parent.classList.remove("scrollbar");
    }
  });
  window.onresize = () => {
    elements.forEach(e => {
      let parent = e.parentNode.parentNode;
      if (e.offsetHeight > e.parentNode.parentNode.offsetHeight) {
        parent.classList.add("scrollbar");
      } else {
        parent.classList.remove("scrollbar");
      }
    });
  };
};

const slideContent = (num, pos, el1, el2, el3) => {
  if (num === 2) {
    switch (pos) {
      case 1:
        el1.style.transform = "translateX(0)";
        el2.style.transform = "translateX(100%)";
        break;
      case 2:
        el1.style.transform = "translateX(-100%)";
        el2.style.transform = "translateX(0)";
        break;
      default:
        console.log("Insert position");
    }
  } else if (num === 3) {
    switch (pos) {
      case 1:
        el1.style.transform = "translateX(0)";
        el2.style.transform = "translateX(100%)";
        el3.style.transform = "translateX(200%)";
        break;
      case 2:
        el1.style.transform = "translateX(-100%)";
        el2.style.transform = "translateX(0)";
        el3.style.transform = "translateX(100%)";
        break;
      case 3:
        el1.style.transform = "translateX(-200%)";
        el2.style.transform = "translateX(-100%)";
        el3.style.transform = "translateX(0)";
        break;
      default:
        console.log("Insert position");
    }
  }
};

const handleSortChange = sortBy => {
  sortBy = sortBy.toLowerCase();
  if (sortBy === "datum") {
    const sortByDate = this.state.sviTenderi.sort((a, b) => {
      return new Date(b.datum_objave) - new Date(a.datum_objave);
    });
    return sortByDate;
  } else if (sortBy === "cijena") {
    const sortByPrice = this.state.sviTenderi.sort((a, b) => {
      return b.cijena - a.cijena;
    });
    return sortByPrice;
  } else if (sortBy === "naručilac") {
    const sortByName = this.state.sviTenderi.sort((a, b) => {
      return a.narucilac.narucilac_naziv.localeCompare(
        b.narucilac.narucilac_naziv
      );
    });
    return sortByName;
  }
};

const emailIsValid = email => {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
};

const parseDate = date => {
  const dateArray = date.split(".");
  const formatedDate = `${dateArray[1]}.${dateArray[0]}.${dateArray[2]}`;
  const datum = new Date(formatedDate);

  const dan = datum.toLocaleDateString("sr-latn-SR", { day: "numeric" });
  const mjesec = datum.toLocaleDateString("sr-latn-SR", { month: "long" });
  const godina = datum
    .toLocaleDateString("sr-latn-SR", { year: "2-digit" })
    .replace(".", "");

  return { dan, mjesec, godina };
};

const sortItems = (array, type, direction, itemName) => {
  let sortedArray;
  if (typeof type === "string") {
    if (type !== "datum" && type !== "narucilacOdluke") {
      const removeString = String.fromCharCode(34);
      const removeString1 = String.fromCharCode(733);
      if (direction) {
        sortedArray = array.sort((a, b) => {
          return a[itemName]
            .replace(removeString, "")
            .replace(removeString1, "")
            .localeCompare(
              b[itemName].replace(removeString, "").replace(removeString1, "")
            );
        });
      } else {
        sortedArray = array.sort((a, b) => {
          return b[itemName]
            .replace(removeString, "")
            .replace(removeString1, "")
            .localeCompare(
              a[itemName].replace(removeString, "").replace(removeString1, "")
            );
        });
      }
    } else if (type === "narucilacOdluke") {
      if (direction) {
        sortedArray = array.sort((a, b) => {
          return a.narucilac.naziv.localeCompare(b.narucilac.naziv);
        });
      } else {
        sortedArray = array.sort((a, b) => {
          return b.narucilac.naziv.localeCompare(a.narucilac.naziv);
        });
      }
    } else {
      if (direction) {
        sortedArray = array.sort((a, b) => {
          return new Date(a[itemName]) - new Date(b[itemName]);
        });
      } else {
        sortedArray = array.sort((a, b) => {
          return new Date(b[itemName]) - new Date(a[itemName]);
        });
      }
    }
  } else {
    if (direction) {
      sortedArray = array.sort((a, b) => {
        let cijena1 = b[itemName];
        let cijena2 = a[itemName];
        return cijena1 - cijena2;
      });
    } else {
      sortedArray = array.sort((a, b) => {
        let cijena1 = b[itemName];
        let cijena2 = a[itemName];
        return cijena2 - cijena1;
      });
    }
  }
  return { sortedArray, direction };
};

const replaceMonth = mjesec => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  if (mjesec === "januar") {
    return monthNames[0];
  }
  if (mjesec === "februar") {
    return monthNames[1];
  }
  if (mjesec === "mart") {
    return monthNames[2];
  }
  if (mjesec === "april") {
    return monthNames[3];
  }
  if (mjesec === "maj") {
    return monthNames[4];
  }
  if (mjesec === "jun") {
    return monthNames[5];
  }
  if (mjesec === "jul") {
    return monthNames[6];
  }
  if (mjesec === "avgust") {
    return monthNames[6];
  }
  if (mjesec === "septembar") {
    return monthNames[8];
  }
  if (mjesec === "oktobar") {
    return monthNames[9];
  }
  if (mjesec === "novembar") {
    return monthNames[10];
  }
  if (mjesec === "decembar") {
    return monthNames[11];
  }
};

const animateScrollTo = (element, startValue, endValue, durationMs) => {
  const easeOutQuint = (t, b, c, d) => {
    return c * ((t = t / d - 1) * t * t * t * t + 1) + b;
  };

  var startTime = new Date();
  var ammountToChange = endValue - startValue;

  const myStopFunction = f => {
    clearInterval(f);
  };

  var animation = setInterval(function() {
    var time = new Date() - startTime;
    if (time < durationMs) {
      element.scrollTo(
        0,
        easeOutQuint(time, startValue, ammountToChange, durationMs)
      );
    } else {
      window.scrollTo(0, endValue);
      myStopFunction(animation);
    }
  }, 13);
};

const formatDateForSafari = date => {
  return date.split("-").join("/");
};

const dateLocalisation = date => {
  const datum = new Date(date);
  const options = {
    day: "numeric",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit"
  };
  return datum.toLocaleDateString("sr-latn-SR", options);
};

const dateLocalisationShort = date => {
  const datum = new Date(date);
  const options = {
    day: "numeric",
    month: "2-digit",
    year: "numeric"
  };
  return datum.toLocaleDateString("sr-latn-SR", options);
};

const createSlug = str => {
  str = String(str).toString();
  str = str.replace(/^\s+|\s+$/g, "");
  str = str.toLowerCase();

  Object.keys(swaps).forEach(swap => {
    swaps[swap].forEach(s => {
      str = str.replace(new RegExp(s, "g"), swap);
    });
  });
  return str
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");
};

export {
  formatMoney,
  moveIndicator,
  evaluateHeight,
  slideContent,
  handleSortChange,
  emailIsValid,
  parseDate,
  sortItems,
  replaceMonth,
  animateScrollTo,
  dateLocalisation,
  createSlug,
  formatDateForSafari,
  dateLocalisationShort
};
