import React, { Component } from "react";
import { CustomInput } from "../Form/Inputs/Inputs";
import { CustomButton } from "../Buttons/Button";
import { addTeamValidation } from "../../functions/validateFunctions";
import { editTeam } from "../../functions/postFunctions";

class EditTeam extends Component {
  state = {
    id: 0,
    name: "",
    location: "",
    logo: null,
    showErrorPopup: false,
    errors: [],
    responseErrors: []
  };

  handleInput = (name, value) => {
    this.setState({ [name]: value });
  };

  handleEditFileInput = event => {
    console.log(event);
    this.setState({ logo: [...event] });
  };

  handleSubmit = event => {
    event.preventDefault();
    const errors = addTeamValidation(this.state, true);
    const { name, location, logo, id } = this.state;
    if (errors.length === 0) {
      const data = new FormData();
      if (logo) {
        logo.map(e => {
          data.append("logo", e);
        });
      }
      data.append("name", name);
      data.append("location", location);
      data.append("id", id);
      editTeam(data, this.props.token).then(res => {
        if (res.success) {
          this.setState({ name: "", location: "", logo: null }, () => {
            this.props.refreshList();
          });
        } else {
          let responseErrors = [];
          Object.keys(res.errors).map((key, index) => {
            responseErrors.push(res.errors[key]);
          });
          this.setState({ responseErrors });
        }
      });
    } else {
      this.setState({ errors });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.errors != this.state.errors && this.state.errors.length > 0) {
      this.setState({ showErrorPopup: true });
    }
    if (
      prevState.responseErrors != this.state.responseErrors &&
      this.state.responseErrors.length > 0
    ) {
      this.setState({ showErrorPopup: true });
    }
    if (prevProps.team !== this.props.team && this.props.team) {
      const { team } = this.props;
      this.setState({ name: team.name, location: team.location, id: team.id });
    }
  }

  removePopup = () => {
    this.setState({ showErrorPopup: false, errors: [], responseErrors: [] });
  };

  render() {
    const { name, location } = this.state;
    const { visible, hideModal } = this.props;
    return (
      <div
        className={`addTeamModal d-flex justify-content-center align-items-center ${
          visible ? "visible" : ""
        }`}
      >
        <div
          className={` errorPopup ${
            this.state.showErrorPopup ? "popupShown" : ""
          }`}
        >
          <div className="content py-20 px-10 d-flex justify-content-between flex-column">
            <h3 className="f-s-18 f-w-4 uppercase text-center">
              Problemi prilikom kreiranja tima
            </h3>
            {this.state.errors.length > 0 ? (
              <ul className="mt-30">
                {this.state.errors.map((e, index) => {
                  return (
                    <li key={index} className="f-s-16 text-center">
                      {e}
                    </li>
                  );
                })}
              </ul>
            ) : (
              <ul className="mt-30">
                {this.state.responseErrors.map(e => {
                  return e.map((el, index) => (
                    <li key={index} className="f-s-16 text-center">
                      {e}
                    </li>
                  ));
                })}
              </ul>
            )}
            <div className="button text-center mt-30">
              <CustomButton onClick={this.removePopup}>Zatvori</CustomButton>
            </div>
          </div>
        </div>
        <div className="addTeamContent py-30 px-30">
          <h4 className="text-color-primary f-s-20 lh text-center mb-20">
            Izmjeni tim
          </h4>
          <div className="row">
            <div className="col-lg-6">
              <CustomInput
                label="Naziv"
                value={name}
                handleChange={this.handleInput}
                name="name"
                index={0}
                counter={false}
              />
            </div>
            <div className="col-lg-6">
              <CustomInput
                label="Lokacija"
                value={location}
                handleChange={this.handleInput}
                name="location"
                index={0}
                counter={false}
              />
            </div>
          </div>
          <div className="row mt-40">
            <div className="col-lg-12 galleryWrapper">
              <CustomInput
                label="Izaberi logo"
                value={null}
                handleChange={this.handleEditFileInput}
                name="editLogo"
                index={0}
                type="file"
              />
              {this.state.logo ? (
                <div className="selectedImages my-20 py-10 px-15">
                  <h3 className="f-s-16 text-color-primary f-w-4">
                    Fotografija odabrana za upload:
                  </h3>
                  <ul className="mt-20">
                    {this.state.logo.map((e, index) => {
                      return (
                        <li key={index} className="f-s-12">
                          {e.name}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="row mt-40">
            <div className="col-lg-12 d-flex justify-content-center">
              <CustomButton
                className="mr-30"
                onClick={e => this.handleSubmit(e)}
              >
                Izmjeni tim
              </CustomButton>
              <CustomButton className="red" onClick={hideModal}>
                Odustani
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditTeam;
