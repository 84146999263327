import React from "react";

const Table = ({ className, theads, children }) => {
  return (
    <table className={`${className} table`}>
      <thead className="thead">
        <tr className="th">
          {theads.map((e, index) => {
            if (e !== "Rezultat") {
              return (
                <th
                  className="f-s-18 text-left text-color-font-l f-w-4 px-10"
                  key={index}
                >
                  {e}
                </th>
              );
            } else {
              return (
                <th
                  className="f-s-18 text-center text-color-font-l f-w-4 px-10"
                  key={index}
                >
                  {e}
                </th>
              );
            }
          })}
        </tr>
      </thead>
      <tbody className="tbody">{children}</tbody>
    </table>
  );
};

export default Table;
