import React, { Component } from "react";
import {
  addPlayerCategory,
  deletePlayerCategory,
  deleteTeam,
  editPlayerCategory,
  getAllCategories,
  getAllTeams
} from "../../../functions/postFunctions";
import { CustomButton } from "../../../components/Buttons/Button";
import Table from "../../../components/Table/Table";
import { TeamPlaceholder } from "../../../components/icons/icons";
import { CustomInput } from "../../../components/Form/Inputs/Inputs";

class PlayerCategoryPage extends Component {
  state = {
    theads: ["Naziv", "Akcije"],
    categories: [],
    showAddTeam: false,
    showEditTeam: false,
    teamForEdit: null,
    name: "",
    addingCategory: false,
    categoryToDelete: 0,
    warningModal: false
  };

  componentDidMount() {
    this.props.setBreadcrumbs("Kategorije igrači");
    getAllCategories().then(res => {
      if (res.success) {
        this.setState({ categories: res.categories });
      }
    });
  }

  handleDeletePage = id => {
    deleteTeam(id, this.props.token).then(res => {
      if (res.success) {
        this.setState({ teams: res.teams });
      }
    });
  };

  hideModal = () => {
    this.setState({
      showAddTeam: false,
      showEditTeam: false,
      teamForEdit: null
    });
  };

  addTeamToList = team => {
    this.setState(prevState => ({
      teams: [...prevState.teams, team],
      showAddTeam: false
    }));
  };

  refreshList = () => {
    getAllTeams().then(res =>
      this.setState({
        teams: res.teams,
        showEditTeam: false,
        teamForEdit: null
      })
    );
  };

  handleInput = (name, value, index) => {
    if (!index && index !== 0) {
      this.setState({
        [name]: value
      });
    } else {
      this.setState(({ categories }) => ({
        categories: [
          ...categories.slice(0, index),
          {
            ...categories[index],
            [name]: value
          },
          ...categories.slice(index + 1)
        ]
      }));
    }
  };

  handleAddCategory = () => {
    const { name } = this.state;
    this.setState({ addingCategory: true });
    addPlayerCategory(name, this.props.token).then(res => {
      if (res.success) {
        this.setState({
          categories: res.categories,
          name: "",
          addingCategory: false
        });
      } else {
        alert(res.errors.name);
        this.setState({ addingCategory: false });
      }
    });
  };

  handleDeleteCategory = () => {
    const { categoryToDelete } = this.state;
    deletePlayerCategory(categoryToDelete, this.props.token).then(res => {
      if (res.success) {
        this.setState({
          categories: res.categories,
          name: "",
          categoryToDelete: 0,
          warningModal: false
        });
      } else {
        alert("Something went wrong");
        this.setState({ warningModal: false });
      }
    });
  };

  showWarningModal = id => {
    this.setState({ categoryToDelete: id, warningModal: true });
  };

  removePopup = () => {
    this.setState({ warningModal: false, categoryToDelete: 0 });
  };

  handleUpdateCategory = id => {
    const category = this.state.categories.find(e => e.id === id);
    editPlayerCategory(category.id, category.name, this.props.token).then(
      res => {
        if (res.success) {
          this.setState({ categories: res.categories });
          alert("Kategorija uspješno ažurirana.");
        } else {
          alert(res.errors.name.map(e => e));
        }
      }
    );
  };

  render() {
    const { theads, categories } = this.state;

    return (
      <div>
        <div
          className={`errorPopup ${
            this.state.warningModal ? "popupShown" : ""
          }`}
        >
          <div className="content py-20 px-10 d-flex justify-content-between flex-column">
            <h3 className="f-s-22 f-w-4 uppercase text-center text-color-red">
              Upozorenje
            </h3>
            <h5 className="f-s-16 f-w-4 uppercase text-center">
              Ukoliko izbrišete kategoriju, svi igrači koji pripadaju ovoj
              kategoriji će takođe biti izbirsani. Da li ste sigurni da želite
              da nastavite?
            </h5>
            <div className="button text-center mt-30">
              <CustomButton
                className="mr-10 red"
                onClick={this.handleDeleteCategory}
              >
                Izbriši
              </CustomButton>
              <CustomButton onClick={this.removePopup}>Odustani</CustomButton>
            </div>
          </div>
        </div>
        <div className="pageTop mb-40">
          {this.state.addingCategory ? (
            <div className="content py-20 px-10 d-flex justify-content-center flex-column">
              <h3 className="f-s-22 f-w-4 uppercase text-center text-color-primary">
                Dodavanje kategorije
              </h3>
              <div className="optimization loader d-flex justify-content-center align-items-center">
                <div className="fancy-spinner">
                  <div className="ring" />
                  <div className="ring" />
                  <div className="dot" />
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-4">
                <CustomInput
                  label="Naziv"
                  value={this.state.name}
                  handleChange={this.handleInput}
                  name="name"
                />
              </div>
              <div className="col-md-4">
                <CustomButton onClick={this.handleAddCategory}>
                  Dodaj kategoriju
                </CustomButton>
              </div>
            </div>
          )}
        </div>

        <Table theads={theads}>
          {categories.map((e, index) => {
            return (
              <tr key={index}>
                <td className="td px-10">
                  <CustomInput
                    value={e.name}
                    handleChange={this.handleInput}
                    name="name"
                    index={index}
                  />
                </td>
                <td className="td px-10" style={{ minWidth: "220px" }}>
                  <CustomButton
                    onClick={() => this.handleUpdateCategory(e.id)}
                    className="mr-20"
                  >
                    Izmjeni
                  </CustomButton>
                  <CustomButton
                    onClick={() => this.showWarningModal(e.id)}
                    className="red"
                  >
                    Izbriši
                  </CustomButton>
                </td>
              </tr>
            );
          })}
        </Table>
      </div>
    );
  }
}

export default PlayerCategoryPage;
