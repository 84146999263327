import React, { Component } from "react";
import {
  deleteMenu,
  deleteMenuItem,
  deleteProductCategory,
  getAllProductCategory,
  editProductCategory
} from "../../../functions/postFunctions";
import { CustomButton } from "../../../components/Buttons/Button";
import SortableList from "../../../components/SortableGrid/SortableList";
import AddProductCategory from "../../../components/AddProductCategory/AddProductCategory";
import EditProductCategory from "../../../components/EditProductCategory/EditProductCategory";
import SearchableDropdown from "../../../components/Form/Dropdown/SearchableDropdown";

class ProductsCategory extends Component {
  state = {
    theads: ["Naziv", "Pozicija", "Akcije"],
    menus: [],
    positions: [
      {
        value: 0,
        label: "Glavni Meni"
      },
      {
        value: 1,
        label: "Meni na vrhu"
      },
      {
        value: 2,
        label: "Lijevi meni"
      },
      {
        value: 3,
        label: "Desni Meni"
      },
      {
        value: 4,
        label: "Meni u podnožju - Lijevo"
      },
      {
        value: 5,
        label: "Meni u podnožju - Desno"
      }
    ],
    allCategories: [],
    items: [],
    showAddMenuItem: false,
    showEditMenuItem: false,
    itemForEdit: null,
    menu: null,
    currentCategory: [],
    dropdownCategories: [],
    modalBtn: false,
    tempId: null,
    checkedBtn: false,
    allBtn: [],
  };

  componentDidMount() {
    this.props.setBreadcrumbs("Kategorije proizvoda");
    getAllProductCategory().then(res => {
      let dropdownCategories = res.categories.map(e => ({
        value: e.id,
        label: e.name
      }));

      res.categories.map(el => {
        let temp;

        if(el.visibility === 1){
          temp = false;
        }else{
          temp = true;
        }
        let data = {
           element: el.id,
           value: temp
        }
        this.setState(prevState => ({
          allBtn: [...prevState.allBtn, data]
        }))
      });

      dropdownCategories.unshift({ value: 0, label: "Sve kategorije" });
      if (res.success) {
        this.setState({
          allCategories: res.categories,
          items: [res.categories[0]],
          currentCategory: { value: 0, label: "Sve kategorije" },
          dropdownCategories
        });
      }
    });
  }

  addToList = items => {
    this.setState({ items }, () => this.hideModal());
    
  };

  handleDeletePage = id => {
    deleteMenu(id, this.props.token).then(res => {
      if (res.success) {
        this.setState({ menus: res.menus });
      }
    });
  };

  findAllMenus = array => {
    let menus = [];
    const handleRecursion = arr => {
      arr.forEach(e => {
        menus.push(e);
        if (e.children.length > 0) {
          handleRecursion(e.children);
        }
      });
    };
    handleRecursion(array);
    return menus;
  };

  showModal = (name, id) => {
    let item;
    if (id) {
      const items = this.findAllMenus(this.state.items);
      item = items.find(e => e.id === id);
    }
    this.setState({ [name]: true, itemForEdit: item });
  };

  showChecked = (checked, id) => {
    checked = !checked;
    

    let data = {
      element: id,
      value: checked
    }
    const index = this.state.allBtn.findIndex(emp => emp.element === id);
         let employees = [...this.state.allBtn] // important to create a copy, otherwise you'll modify state outside of setState call
    employees[index] = data;
    this.setState({ allBtn: employees});

    const data2 = new FormData()

    let tempID = this.state.allCategories.find(item =>  item.id === id);
    
    let tempValue;

    if(checked === true){
      tempValue = 0;
    }else{
      tempValue = 1;
    }

    console.log(tempValue)



    data2.append('id', tempID.id);
    data2.append('name', tempID.name);
    data2.append('order', tempID.order);
    data2.append('level', tempID.level);
    data2.append('description', tempID.description);
    data2.append('parent_id', tempID.parent_id);
    data2.append('slug', tempID.slug);
    data2.append('discount_id', tempID.discound_id);
    data2.append('cover', tempID.cover);
    data2.append('image', tempID.image);
    data2.append('visibility', tempValue);
    console.log('--------------------')
       // Display the values
   
    editProductCategory(data2, this.props.token).then(res => {
      if (res.success) {
        
      } else {
        let responseErrors = [];
        Object.keys(res.errors).forEach((key, index) => {
          responseErrors.push(res.errors[key]);
        });
        this.setState({ responseErrors });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    
    if (prevState.currentCategory !== this.state.currentCategory) {
      let items;
      if (this.state.currentCategory.value === 0) {
        items = this.state.allCategories.map(e => ({
          name: e.name,
          id: e.id,
          level: e.level,
          order: e.order,
          children: []
        }));
        this.setState({ items: [] }, () => this.setState({ items }));
      } else {
        items = this.state.allCategories.find(
          e => e.id === this.state.currentCategory.value
        );
        this.setState({ items: [] }, () => this.setState({ items: [items] }));
      }
    }
  }

  hideModal = () => {
    this.setState({
      showAddMenuItem: false,
      itemForEdit: null
    });
   
  };

  handleDeleteMenuItem = id => {
    this.setState({ items: [] });
    deleteMenuItem(id, this.props.match.params.id, this.props.token).then(
      res => {
        if (res.success) {
          this.setState({ items: res.items });
        }
      }
    );
  };

  handleInput = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  deleteCategory = id => {
    this.setState({ items: [] });
    deleteProductCategory(id, this.props.token).then(res => {
      if (res.success) {
        this.setState({ items: res.categories });
        this.setState(prevState => ({modalBtn: !prevState.modalBtn}));
      }
    });
  };

  showPopUp = id => {
    this.setState({ tempId: id});
    this.setState(prevState => ({modalBtn: !prevState.modalBtn}));
  };

  listElement = el => {
    return (
      
      <SortableList
        items={el}
        key={this.props.key}
        listElement={this.listElement}
        token={this.props.token}
        deleteCategory={this.showPopUp}
        showModal={this.showModal}
        categories
        allBtn={this.state.allBtn}
        showChecked={this.showChecked}
      />
     
    );
  };
   
  render() {
    const {
      items,
      currentCategory,
      dropdownCategories,
      allCategories
    } = this.state;
    return (
      <div>
        <div className="pageTop mb-40">
          <div className="row">
            <div className="col-md-3">
              <SearchableDropdown
                data={dropdownCategories}
                placeholder="Kategorija"
                name="currentCategory"
                handleChange={this.handleInput}
                value={currentCategory}
              />
            </div>
            <div className="col-md-3 ">
              <CustomButton onClick={() => this.showModal("showAddMenuItem")}>
                Dodaj kategoriju
              </CustomButton>
            </div>

          </div>
          <AddProductCategory
            visible={this.state.showAddMenuItem}
            hideModal={this.hideModal}
            token={this.props.token}
            addToList={this.addToList}
            menus={allCategories}
            id={this.props.match.params.id}
          />
          <EditProductCategory
            visible={this.state.showEditMenuItem}
            hideModal={this.hideModal}
            token={this.props.token}
            addToList={this.addToList}
            menus={allCategories}
            id={this.props.match.params.id}
            itemForEdit={this.state.itemForEdit}
            menuName={this.state.menu && this.state.menu.name}
          />
        </div>

        {this.state.modalBtn ? 
        <div
        className={`addTeamModal d-flex justify-content-center align-items-center ${
          this.state.modalBtn ? "visible" : ""
        }`} style={{overflowY: 'auto'}} >
            <div className="addTeamContent py-30 px-30">
              <h4 className="text-color-primary f-s-20 lh text-center mb-20">
              Da li ste sigurni?
              </h4>
      
              <div className="row mt-40">
                <div className="col-lg-12 d-flex justify-content-center">
                  <CustomButton
                    className="mr-30 green"
                    onClick={() => this.deleteCategory(this.state.tempId)}
                  >
                    Potvrdi
                  </CustomButton>
                  
                  <CustomButton onClick={(e) => {this.setState(prevState => ({modalBtn: !prevState.modalBtn}));}}>
                    Odustani
                  </CustomButton>
                </div>
            </div>
      
            </div>
        </div> : null}

        <div className="row my-20">
          <div className="col-12">
            <div className="menuList">
              {items.length > 0 ? (
                this.listElement(items) 
              ) : (
                <div className="content py-20 px-10 d-flex justify-content-center flex-column">
                  <h3 className="f-s-22 f-w-4 uppercase text-center text-color-primary">
                    Učitavanje
                  </h3>
                  <div className="optimization loader d-flex justify-content-center align-items-center">
                    <div className="fancy-spinner">
                      <div className="ring" />
                      <div className="ring" />
                      <div className="dot" />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductsCategory;
