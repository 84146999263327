import React, { Component } from "react";
import { CustomInput } from "../../../components/Form/Inputs/Inputs";

import { CustomButton } from "../../../components/Buttons/Button";
import { addStaffValidation } from "../../../functions/validateFunctions";
import { addStaff } from "../../../functions/postFunctions";
import DatePicker from "react-date-picker";

class AddStaff extends Component {
  state = {
    name: "",
    position: "",
    birth_date: new Date(),
    birth_place: "",
    nationality: "",
    playing_career: "",
    coaching_career: "",
    trophies: "",
    errors: [],
    showErrorPopup: false,
    showWarningPopup: false,
    image: null,
    active: "",
    showUploadPopup: false,
    uploadComplete: false
  };

  componentDidMount() {
    this.props.setBreadcrumbs("Dodaj člana");
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.errors != this.state.errors && this.state.errors.length > 0) {
      this.setState({ showErrorPopup: true });
    }
    if (prevState.stepOne !== this.state.stepOne) {
      const item = document.querySelector(".gallery");
      this.navigationMove(item, "gallery");
    }
  }

  handleInput = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  handleFileInput = event => {
    this.setState({ image: [...event] });
  };

  removePopup = () => {
    this.setState({ showErrorPopup: false, showWarningPopup: false });
  };

  handleSubmit = event => {
    event.preventDefault();
    const errors = addStaffValidation(this.state);
    const {
      name,
      position,
      birth_date,
      birth_place,
      nationality,
      playing_career,
      coaching_career,
      trophies,
      image
    } = this.state;
    const dataObject = {
      name,
      position,
      birth_date: birth_date.toISOString(),
      birth_place,
      nationality,
      playing_career,
      coaching_career,
      trophies: trophies !== "" ? trophies : " "
    };

    if (errors.length === 0) {
      const data = new FormData();
      image.forEach(e => {
        data.append("image", e);
      });

      Object.keys(dataObject).forEach(key => {
        data.append(key, dataObject[key]);
      });

      this.setState({ showUploadPopup: true });
      const config = {
        onUploadProgress: progressEvent => {
          const completed = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          this.setState({
            completed,
            uploadComplete: completed === 100
          });
        }
      };

      addStaff(data, this.props.token, config).then(res => {
        if (res.success) {
          this.props.history.push("/strucni-stab");
        } else {
          alert(res.message);
        }
      });
    } else {
      this.setState({ errors });
    }
  };

  render() {
    return (
      <div className="wrapper">
        <div
          className={`errorPopup ${
            this.state.showWarningPopup ? "popupShown" : ""
          }`}
        >
          <div className="content py-20 px-10 d-flex justify-content-between flex-column">
            <h3 className="f-s-22 f-w-4 uppercase text-center text-color-red">
              Upozorenje
            </h3>
            <h5 className="f-s-16 f-w-4 uppercase text-center">
              Nijedna fotografija nije selektovana. Ukoliko nastavite default-na
              cover fotografija će biti korištena
            </h5>
            <div className="button text-center mt-30">
              {/*<CustomButton className="mr-10 red" onClick={this.createPage}>*/}
              {/*  Nastavi*/}
              {/*</CustomButton>*/}
              <CustomButton onClick={this.removePopup}>Zatvori</CustomButton>
            </div>
          </div>
        </div>
        <div
          className={`errorPopup ${
            this.state.showUploadPopup ? "popupShown" : ""
          }`}
        >
          <div className="content py-20 px-10 d-flex justify-content-center flex-column">
            <h3 className="f-s-22 f-w-4 uppercase text-center text-color-primary">
              {!this.state.uploadComplete
                ? "Upload u toku"
                : "Optimizacija u toku"}
            </h3>
            {!this.state.uploadComplete ? (
              <div className="uploadBar d-flex align-items-center p-r">
                <div className="barWrapper">
                  <span
                    className="bar d-flex align-items-center justify-content-center"
                    style={{ width: `${this.state.completed}%` }}
                  />
                </div>
                <span className="outerProgress f-s-16 center-a-b text-color-primary">
                  {this.state.completed}%
                </span>
              </div>
            ) : (
              <div className="optimization loader d-flex justify-content-center align-items-center">
                <div className="fancy-spinner">
                  <div className="ring" />
                  <div className="ring" />
                  <div className="dot" />
                </div>
              </div>
            )}
          </div>
        </div>
        {this.state.stepOne && (
          <div className="innerNavigation pb-10 mb-40">
            <span
              className="f-s-16 mr-20 uppercase text-color-primary link"
              onClick={e => this.navigationMove(e.currentTarget, "content")}
            >
              Sadržaj
            </span>
            <span
              className="f-s-16 uppercase text-color-font-l link gallery"
              onClick={e => this.navigationMove(e.currentTarget, "gallery")}
            >
              Galerija
            </span>
            <span className="indicator" ref={this.underlineRef} />
          </div>
        )}
        <div className="contentWrapper">
          <div
            className={` errorPopup ${
              this.state.showErrorPopup ? "popupShown" : ""
            }`}
          >
            <div className="content py-20 px-10 d-flex justify-content-between flex-column">
              <h3 className="f-s-18 f-w-4 uppercase text-center">
                Problemi prilikom kreiranja stranice
              </h3>
              <ul className="mt-30">
                {this.state.errors.map((e, index) => {
                  return (
                    <li key={index} className="f-s-16 text-center">
                      {e}
                    </li>
                  );
                })}
              </ul>
              <div className="button text-center mt-30">
                <CustomButton onClick={this.removePopup}>Zatvori</CustomButton>
              </div>
            </div>
          </div>
          <form onSubmit={null}>
            <div className="row mt-20">
              <div className="col-md-4">
                <CustomInput
                  label="Ime i prezime"
                  value={this.state.name}
                  handleChange={this.handleInput}
                  name="name"
                />
              </div>
              <div className="col-md-3">
                <DatePicker
                  onChange={e => this.handleInput("birth_date", e, 0)}
                  value={this.state.birth_date}
                  locale={"bs-BS"}
                />
              </div>
              <div className="col-md-5">
                <CustomInput
                  label="Mjesto rođenja"
                  value={this.state.birth_place}
                  handleChange={this.handleInput}
                  name="birth_place"
                />
              </div>
            </div>
            <div className="row mt-20">
              <div className="col-md-3">
                <CustomInput
                  label="Nacionalnost"
                  value={this.state.nationality}
                  handleChange={this.handleInput}
                  name="nationality"
                />
              </div>
              <div className="col-md-3">
                <CustomInput
                  label="Pozicija"
                  value={this.state.position}
                  handleChange={this.handleInput}
                  name="position"
                />
              </div>
              <div className="col-md-6">
                <CustomInput
                  label="Igračka karijera"
                  value={this.state.playing_career}
                  handleChange={this.handleInput}
                  name="playing_career"
                />
              </div>
            </div>
            <div className="row mt-20">
              <div className="col-md-6">
                <CustomInput
                  label="Trenerska karijera"
                  value={this.state.coaching_career}
                  handleChange={this.handleInput}
                  name="coaching_career"
                />
              </div>
              <div className="col-md-6">
                <CustomInput
                  label="Trofeji"
                  value={this.state.trophies}
                  handleChange={this.handleInput}
                  name="trophies"
                />
              </div>
            </div>

            <div className="row mt-40">
              <div className="col-12">
                <div className="galleryWrapper">
                  <CustomInput
                    label="Dodaj fotografiju"
                    value={null}
                    handleChange={this.handleFileInput}
                    name="image"
                    type="file"
                  />
                  {this.state.image ? (
                    <div className="selectedImages my-20 py-10 px-15">
                      <h3 className="f-s-16 text-color-primary f-w-4">
                        Fotografija odabrana za upload:
                      </h3>
                      <ul className="mt-20">
                        {this.state.image.map((e, index) => {
                          return (
                            <li key={index} className="f-s-13">
                              {e.name}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="row mt-30">
          <div className="col-12 d-flex justify-content-end">
            <CustomButton onClick={this.handleSubmit}>Dodaj člana</CustomButton>
          </div>
        </div>
      </div>
    );
  }
}

export default AddStaff;
