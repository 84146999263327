import React, { Component } from "react";

import { Link } from "react-router-dom";

import { moveIndicator } from "./../../functions/functions";
import { LogoutIcon, AvatarIcon } from "../icons/icons";

class Header extends Component {
  state = {
    notifications: false,
    notificationsData: null,
    logout: false
  };

  logout = () => {
    localStorage.clear();
    window.location = "/";
  };
  render() {
    return (
      <div id="header">
        <div className="breadcrumbs p-r d-inline-block ml-40">
          <span className="center-r-v d-inline-block f-s-14">
            {this.props.breadcrumbs}
          </span>
        </div>
        <div className="headerInfo d-flex p-r">
          {/*<div className="profile d-inline-block">*/}
          {/*  <Link to="/podesavanja">*/}
          {/*    <div className="imgContainer">*/}
          {/*      <AvatarIcon />*/}
          {/*    </div>*/}
          {/*  </Link>*/}
          {/*</div>*/}
          <div className="logout d-inline-block d-flex align-items-center justify-content-center">
            <button onClick={this.logout}>
              <LogoutIcon />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
