import React, { Component } from "react";
import {
  deletePlayer,
  deleteStaff,
  getAllPlayers,
  getAllStaff
} from "../../functions/postFunctions";
import { CustomButton, LinkButton } from "../../components/Buttons/Button";
import Table from "../../components/Table/Table";
import {
  dateLocalisation,
  formatDateForSafari
} from "../../functions/functions";

class StaffPage extends Component {
  state = {
    theads: ["Fotografija", "Ime i prezime", "Pozicija", "Akcije"],
    staff: []
  };

  componentDidMount() {
    this.props.setBreadcrumbs("Stručni štab");
    getAllStaff().then(res => {
      if (res.success) {
        this.setState({ staff: res.staff });
      }
    });
  }

  handleDeleteStaff = id => {
    deleteStaff(id, this.props.token).then(res => {
      if (res.success) {
        this.setState({ staff: res.staff });
      }
    });
  };

  render() {
    const { theads, staff } = this.state;
    let server;
    if (process.env.NODE_ENV === "development") {
      server = "http://127.0.0.8/";
    } else {
      server = "https://admin.fk-buducnost.me/";
    }
    return (
      <div>
        <div className="pageTop mb-40">
          <LinkButton href="dodaj-clana">Dodaj člana</LinkButton>
        </div>
        <Table theads={theads}>
          {staff.map((e, index) => {
            return (
              <tr key={index}>
                <td className="td px-10">
                  <img
                    src={server + e.image}
                    alt={e.slug}
                    style={{ maxHeight: "100px" }}
                  />
                </td>
                <td className="td px-10">
                  <div className="wrapper">
                    <span className="f-s-16">{e.name}</span>
                  </div>
                </td>
                <td className="td px-10">
                  <span className="f-s-16 capitalize">{e.position}</span>
                </td>
                <td className="td px-10" style={{ minWidth: "220px" }}>
                  <LinkButton href={`izmjeni-clana/${e.id}`} className="mr-20">
                    Izmjeni
                  </LinkButton>
                  <CustomButton
                    onClick={() => this.handleDeleteStaff(e.id)}
                    className="red"
                  >
                    Izbriši
                  </CustomButton>
                </td>
              </tr>
            );
          })}
        </Table>
      </div>
    );
  }
}

export default StaffPage;
